import { useTranslation } from 'react-i18next'
import Checkbox from '~/components/Checkbox'

export type CheckboxINeedAnInvoiceProps = {
  isDefaultChecked: boolean
  onChange: (checked: boolean) => void
}

const CheckboxINeedAnInvoice = ({
  isDefaultChecked,
  onChange
}: CheckboxINeedAnInvoiceProps) => {
  const { t } = useTranslation(['common'])

  const handleINeedAnInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    onChange(checked)
  }

  return (
    <Checkbox
      label={t('common:iNeedAnInvoice')}
      htmlFor="i-need-an-invoice"
      alignVertical="center"
      size="sm"
      type="checkbox"
      onChange={handleINeedAnInvoice}
      checked={isDefaultChecked}
    />
  )
}

export default CheckboxINeedAnInvoice
